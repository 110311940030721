import Glide from '@glidejs/glide';

window.Slideshow = function () {
  this.options = {
    type: 'carousel',
    animationDuration: 1500,
    perView: 1,
    hoverpause: false
  };

  this.slideshow = null;

  this.init = function () {
    const slideCount = document.querySelectorAll('.slide').length;

    if (slideCount > 1) {
      this.options.autoplay = 5000;

      this.slideshow = new Glide('.glide', this.options);
      this.slideshow.mount();
    }
  };

  this.remove = function () {
    this.slideshow.destroy();
  };
};

new Slideshow().init();
